
$body-font-family: 'DM Sans', sans-serif;
$input-font-size: 14px;
$list-item-title-font-size: 12px;
$list-item-subtitle-font-size: 12px; 
$list-item-dense-title-font-size: 12px;
$expansion-panel-header-font-size: 14px;
$expansion-panel-content-font-size: 14px;

// Globals
$border-radius-root: 6px;
$font-size-root: 14px;
